<template>
  <b-card>
    <div class="d-flex">
      <span class="switch switch-icon switch-primary switch-sm">
        <label>
          <input type="checkbox" v-model="showingPanel" name="select" />
          <span></span>
        </label>
      </span>
      <span class="mt-2 ml-1"
        >{{ showingPanel ? "Ocultar" : "Mostrar" }} Panel</span
      >
    </div>
    <transition name="slide-fade">
      <b-row class="bg-secondary p-4" v-show="showingPanel">
        <b-col md="5">
          <label>{{
            !isLoadingTech ? "Brigada" : "Cargando brigadas..."
          }}</label>
          <Select2
            v-model="form.uid"
            :options="configTech"
            :disabled="isLoadingTech"
            class="mt-2"
          />
        </b-col>
        <b-col md="7">
          <b-row>
            <div class="form-group col-6">
              <label>Fecha inicio:</label>
              <input
                v-model="form.date_init"
                :disabled="isLoadingTech"
                type="datetime-local"
                class="form-control form-control-sm"
              />
            </div>
            <div class="form-group col-6">
              <label>Fecha fin:</label>
              <input
                v-model="form.date_end"
                :disabled="isLoadingTech"
                :min="form.date_init"
                type="datetime-local"
                class="form-control form-control-sm"
              />
            </div>
          </b-row>
        </b-col>
        <b-col>
          <transition name="bounce">
            <div
              class="accordion accordion-solid accordion-toggle-plus"
              id="accordionExample3"
              v-if="configTech.length"
            >
              <div class="card">
                <div class="card-header" id="headingOne3">
                  <div
                    class="card-title collapsed text-primary"
                    data-toggle="collapse"
                    data-target="#collapseOne3"
                    aria-expanded="false"
                  >
                    Busqueda avanzada
                  </div>
                </div>
                <div
                  id="collapseOne3"
                  class="collapse show"
                  data-parent="#accordionExample3"
                  style=""
                >
                  <div class="card-body">
                    <b-row>
                      <b-col md="3">
                        Ordenes de trabajo - estados
                        <hr />
                        <div class="checkbox-inline">
                          <label class="checkbox checkbox-success">
                            <input
                              type="checkbox"
                              name="Checkboxes5"
                              @click="selectAll"
                              v-model="allSelected"
                            />
                            <span></span>Seleccionar todo</label
                          >
                        </div>
                        <hr />
                        <div
                          class="checkbox-list"
                          v-for="(item, idx) in statusList"
                          :key="idx"
                        >
                          <label :class="`checkbox ${item.class}`">
                            <input
                              type="checkbox"
                              name="Checkboxes1"
                              :value="item.id"
                              v-model="status"
                              @click="select"
                            />
                            <span></span> {{ item.text }}</label
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </b-col>
      </b-row>
    </transition>
    <br />
    <b-row>
      <b-col md="4">
        <div class="d-flex flex-row">
          <b-button
            @click="searchRoute()"
            class="mr-3 d-flex flex-row"
            size="sm"
            variant="primary"
          >
            <i class="la la-search"></i>
            {{ isLoading ? "Consultando..." : "Consultar" }}
          </b-button>
          <transition name="bounce">
            <b-button
              @click="reset()"
              class="mr-3 d-flex flex-row"
              variant="secondary"
              size="sm"
              v-if="path.length"
            >
              <i class="la la-close"></i>
              <span>Limpiar</span>
            </b-button>
          </transition>
          <BrigadeDraggableModal
            :dataLocations="response"
            :isLoading="response.length > 1"
            @getPosition="getActivePosition"
          />
        </div>
      </b-col>
    </b-row>
    <div class="separator separator-dashed mt-3 mb-3"></div>
    <b-row>
      <b-col>
        <transition name="slide-fade">
          <div v-show="path.length" class="content-tab-running">
            <b-tabs content-class=" mt-3" small>
              <b-tab title="Mapa" active content-class="map-tab">
                <gmap-map
                  id="gmap-map"
                  ref="map"
                  v-show="!isLoading"
                  v-bind:center="center"
                  v-bind:zoom="zoom"
                  style="width: 100%; height: 90vh"
                >
                  <gmap-info-window
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                    style="gmap-info-window-vue"
                  >
                    <div class="infoWindow">
                      <div v-html="infoOptions.content"></div>
                    </div>
                  </gmap-info-window>

                  <gmap-marker
                    v-for="(item, i) in markers"
                    :key="i + '__Brigade__'"
                    :position="item.position"
                    :clickable="true"
                    :animation="2"
                    :icon="item.icon"
                    @click="toggleInfoWindow(item, i)"
                  />
                  <gmap-marker
                    v-for="(item, i) in clickMap"
                    :key="i + '__searched__'"
                    :position="item.position"
                    :clickable="true"
                    :animation="2"
                    @click="toggleInfoWindow(item, i)"
                  />
                  <gmap-marker
                    v-for="(item, i) in current"
                    :key="i + '__vehicle__'"
                    :position="item.position"
                    :clickable="true"
                    :animation="2"
                    :icon="item.icon"
                    @click="toggleInfoWindow(item, i)"
                  />

                  <!-- :position="activePositionData.position" -->
                  <gmap-marker :position="viewMarker" :animation="2" />

                  <gmap-cluster
                    :minimumClusterSize="3"
                    :position="center"
                    :clickable="true"
                    :zoomOnClick="true"
                    :animation="1"
                  >
                    <gmap-marker
                      v-for="(item, i) in ots"
                      :key="i + '__OTS__'"
                      :position="item.position"
                      :clickable="true"
                      :animation="2"
                      :icon="item.icon"
                      @click="toggleInfoWindow(item, i)"
                    />
                  </gmap-cluster>
                  <gmap-marker
                    v-for="(item, i) in markersOt"
                    :key="i + '__Brigade__'"
                    :position="item.position"
                    :clickable="true"
                    :animation="2"
                    @click="toggleInfoWindow(item, i)"
                  />

                  <gmap-polyline
                    v-bind:path.sync="path"
                    v-bind:options="options"
                  />

                  <!-- <b-button
                    @click="showTableLocations()"
                    class="position-absolute position-eye"
                    size="sm"
                    variant="primary"
                  >
                    <Eye />
                  </b-button> -->
                </gmap-map>
                <div class="text-center" v-show="isLoading">
                  <img width="400" :src="GIF" alt="Loader" />
                </div>
              </b-tab>
              <b-tab title="Eventos" v-if="formateAddres.length">
                <b-card>
                  <span class="my-3"> Tabla de direcciones [Generales]</span>
                  <BrigadeReport ref="BREPORT" :dataAddres="formateAddres" />
                </b-card>
              </b-tab>
              <b-tab title="Trabajos" v-if="orders.length">
                <Orders :orders="orders" />
              </b-tab>
              <b-tab title="Gráfica" v-if="chart.length">
                <b-card class="mb-4">
                  <Chart :title="undefined" :orders="chart" />
                </b-card>
                <b-card>
                  <Chart
                    :title="'Cantidad de fechas Por OT'"
                    :orders="chart2"
                  />
                </b-card>
              </b-tab>
            </b-tabs>
          </div>
        </transition>
      </b-col>
    </b-row>
  </b-card>
</template>
<style>
.content-tab-running {
  height: 100vh !important;
}
.content-tab-running .tab-content {
  height: 85vh !important;
  overflow-y: auto !important;
}

/* .position-eye{
  top: 6vh;
  right: 0.5vw;
} */
</style>
<script>
const generarColorHexAleatorio = () => {
  return `#${Math.floor(Math.random() * 0xffffff).toString(16)}`;
};
const symbolOne = {
  path: 0,
  strokeColor: "#009CDE",
  fillColor: "#009CDE",
  fillOpacity: 1,
  strokeWeight: 2,
};
const symbolMedium = {
  path: 1,
  strokeColor: "#00B398",
  fillColor: "#00B398",
  strokeWeight: 3,
  fillOpacity: 1,
};

const symbolEnd = {
  path: 0,
  strokeColor: "#C42E10",
  rotation: 45,
  strokeWeight: 2,
};
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
import ENV from "@/environment";
const URL_ICON =
  process.env.NODE_ENV == "development" ? ENV.ICONS_DEV : ENV.ICONS;

const URL_ICONS = (icon) => {
  return `${URL_ICON}${icon}.png`;
};
import Select2 from "v-select2-component";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import infoWindow from "@/assets/content/admin/infoWindowOpen";

import BrigadeReport from "./modal/BrigadeReport.vue";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import stylesOptions from "@/assets/content/cleanMap";
import toastr from "toastr";
import Orders from "./modal/Orders.vue";
import TechRunning from "@/assets/content/operative/TechRunning";
import Chart from "./modal/Chart.vue";
import BrigadeDraggableModal from "./modal/BrigadeDraggableModal.vue";
// import Eye from "../../processes/works/helpers/icons/Eye.vue";
const ICONS_CLD =
  process.env.NODE_ENV == "development" ? ENV.ICONS_DEV : ENV.ICONS;
export default {
  name: "BrigadeTour",
  components: {
    Select2,
    BrigadeReport,
    GmapCluster,
    Orders,
    Chart,
    BrigadeDraggableModal,
  },
  data() {
    return {
      GIF: "https://i.pinimg.com/originals/c8/a1/76/c8a1765ffc8243f3a7b176c0ca84c3c1.gif",
      form: {
        date_init: moment().format("YYYY-MM-DDT06:00:00"),
        date_end: moment().format("YYYY-MM-DDT18:00:00"),
        // form uid debe ser -1 al iniciar porque el valor por defecto debe ser - seleccion (-1)
        uid: -1,
      },
      isLoading: false,
      showBrigadeTour: false,
      center: { lat: 10.9784174, lng: -74.8144132 },
      zoom: 12,
      markersOt: [],
      formateAddres: [],
      activePositionData: {},
      // tableLocations: [],
      markers: [],
      path: [],
      ots: [],
      orders: [],
      current: [],
      techs: [],
      response: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoWinContent: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      options: {
        strokeColor: "#071D49",
        strokeWeight: 2,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "10%",
          },
          {
            icon: symbolMedium,
            offset: "20%",
          },
          {
            icon: symbolMedium,
            offset: "30%",
          },
          {
            icon: symbolMedium,
            offset: "40%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "60%",
          },
          {
            icon: symbolMedium,
            offset: "70%",
          },
          {
            icon: symbolMedium,
            offset: "80%",
          },
          {
            icon: symbolMedium,
            offset: "90%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },
      configTech: [],
      isLoadingTech: false,

      // checkbox
      allSelected: false,
      status: [],
      statusList: [
        {
          id: 2,
          text: "Asignadas",
          class: "checkbox-primary",
        },
        {
          id: 3,
          text: "Descargadas en el movil",
          class: "checkbox-dark",
        },
        {
          id: 4,
          text: "En ejecución",
          class: "checkbox-info",
        },
        {
          id: 5,
          text: "Ejecutadas",
          class: "checkbox-success",
        },
        {
          id: 6,
          text: "Certificadas",
          class: "checkbox-warning",
        },
        {
          id: 8,
          text: "Rechazadas",
          class: "checkbox-danger",
        },
      ],
      showingPanel: true,
      chart: [],
      chart2: [],
      address: "",
      clickMap: [],
    };
  },
  mounted() {
    this.loadTech();
  },
  beforeMount() {
    setTimeout(() => {
      this.$refs.map.$mapPromise.then((map) => {
        map.setOptions({
          styles: stylesOptions,
        });
      });
    }, 3000);
  },
  methods: {
    filterTech(uid) {
      return this.techs.filter((tech) => tech.usuario === uid);
    },
    toggleInfoWindow(marker, idx, isAlwaysOpen) {
        // console.log(isAlwaysOpen);
      if (isAlwaysOpen) {
        this.infoWindowPos = marker.position;
        this.center = marker.position;
        this.infoOptions.content = marker.infoText;
        this.currentMidx = idx;
        this.infoWinOpen = true;
        return;
      }

      this.infoWindowPos = marker.position;
      this.center = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    contentMarker(marker, indicator) {
      let info = {
        id: marker.usuario,
        position: {
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        },
        icon: `https://sipremsol.co/public/img/icons_cld/${
          indicator == 0 ? "start_running" : "end_running"
        }.png`,
        infoText: ` <div id="content" style="width: 300px !important;">
                      <div id="bodyContent">
                          <strong>
                              <div style="font-weight: bold;">
                                  <span class="text-danger">  ${
                                    marker.nombre
                                  } </span> <br>
                                  <span class="text-danger">  Fecha:  </span> ${moment(
                                    marker.fecha
                                  ).format("LL")} <br>
                                  <span class="text-danger">  Hora ${
                                    indicator == 0 ? "inicio" : "fin"
                                  }: </span> ${moment(
          marker.fecha,
          "dd-mm-yyyy hh:mm"
        ).format("hh:mm a")} <br>
                                  <span class="text-danger">  Télefono: </span> <a href="tel:${
                                    marker.telefono
                                  }"> ${marker.telefono} </a><br>
                                   <span class="text-danger">  Placa: </span> ${marker.placa.toUpperCase()}<br>
                              </div>
                          </strong>
                      </div>
                    </div>`,
      };

      return info;
    },
    reset() {
      this.deletePolylineBrigadeRoute();
      this.orders = [];
      this.response = [];
    },
    async searchRoute() {
      if (
        this.form.uid === -1 ||
        this.form.date_init === null ||
        this.form.date_end === null
      ) {
        this.$swal.fire({
          title: "Información",
          text: "Debe completar toda la información para poder filtrar",
          icon: "info",
        });
      } else {
        this.isLoading = true;
        try {
          this.form.date_init = moment(this.form.date_init).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.form.date_end = moment(this.form.date_end).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.form.estados = this.status;
          this.reset();
          const response = await axios.post("/recorrido-admin", this.form);
          const data = response.data.gps;

          this.current = response.data.ultima_posicion.map((el) => {
            return {
              id: el.usuario,
              technical: true,
              position: {
                lat: parseFloat(el.latitud),
                lng: parseFloat(el.longitud),
              },
              icon: `${ICONS_CLD}tipo/${el.tipo_vehiculo
                .replace(/ /g, "")
                .toLowerCase()}.png`,
              infoText: TechRunning(el),
            };
          });
          if (!data.length) {
            toastr.info("No se ha encontrado información");
            this.isLoading = false;
            return;
          }

          // this.tableLocations = data;

          this.path = data.map((item) => {
            return {
              lat: parseFloat(item.latitud),
              lng: parseFloat(item.longitud),
            };
          });

          this.center = {
            lat: this.path[this.path.length - 1].lat,
            lng: this.path[this.path.length - 1].lng,
          };

          // this.directionsRenderer();

          const [tech] = this.filterTech(this.form.uid);

          const markerInit = {
            lat: this.path[0].lat,
            lng: this.path[0].lng,
            usuario: tech.usuario,
            fecha: this.form.date_init,
            telefono: tech.telefono,
            nombre: tech.nombre,
            placa: tech.placa,
            icon: "https://sipremsol.co/public/img/icons_cld/start_route.png",
          };

          this.markers.push(this.contentMarker(markerInit, 0));
          // this.markers.push(this.contentMarker(markerEnd, 1));

          response.data.gps.map((item) => {
            this.response.push({
              fullDate: `${item.fecha} - ${item.hora}`,
              date: item.fecha,
              hour: item.hora,
              uid: item.usuario,
              lat: parseFloat(item.latitud),
              lng: parseFloat(item.longitud),
            });
          });

          this.orders = response.data.ots;
          // console.log(response.data);

          const status = _.countBy(this.orders, "estado_o_t_s.descripcion");

          this.chart = [];
          for (const key in status) {
            this.chart.push({
              name: key,
              value: status[key],
              color: `${generarColorHexAleatorio()}`,
            });
          }

          // new grafica in tap grafica
          const statusChart = _.countBy(response.data.ots, "fecha_asigna");
          this.chart2 = [];
          for (const key in statusChart) {
            this.chart2.push({
              name: key,
              value: statusChart[key],
              color: `${generarColorHexAleatorio()}`,
            });
          }

          this.response = _.uniqWith(this.response, _.isEqual);
          const fiveLocations = this.getFiveLocations(this.response);

          fiveLocations.map((item, idx) => {
            sleep(3 * 1000);
            this.geocodedAddress(
              {
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lng),
              },
              item.uid,
              item.date,
              item.hour,
              idx
            );
          });

          // this.formateAddres = _.orderBy(this.formateAddres, ["i"], ["desc"]);

          var mak = [];
          response.data.ots.map((ot) => {
            ot.avisos.map((el) => {
              if (el.pivot.id_aviso_ref == el.id_aviso) {
                mak.push({
                  id: el.id_aviso,
                  id_ot: ot.id_ot,
                  position: {
                    lat: parseFloat(el.latitud),
                    lng: parseFloat(el.longitud),
                  },
                  address: el.dir_suscriptor,
                  description: el.observacion,
                  priority: el.ind_peligro,
                  ordenNumber: el.num_aviso,
                  dir_ref: el.dir_ref,
                  ot: true,
                  type: el.tipo_trabajo,
                  ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
                  icon: el.icon ? URL_ICONS(el.icon.icono) : "",
                  infoText: infoWindow(el, ot),
                });
              }
            });
          });
          this.ots = mak;

          this.zoom = 13;
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo ha salido mal",
          });
          console.log(error);
        }
        this.isLoading = false;
      }
    },
    loadTech() {
      let first = [];
      let options = [];
      this.isLoadingTech = true;
      options.push({ id: -1, text: "Seleccione" });
      axios
        .post("/brigadas")
        .then((res) => {
          var data = {
            sectores: JSON.parse(sessionStorage.sectores),
          };
          res.data = res.data.filter(
            (r) => data.sectores.includes(r.delegacion) && r.vehiculo_info
          );
          res.data.map((worker) => {
            first.push({
              id: worker.id,
              nombre: worker.nombre,
              usuario: worker.usuario,
              telefono: worker.telefono ? worker.telefono : "",
              placa: worker.vehiculo_info ? worker.vehiculo_info.placa : "",
            });
          });

          this.techs = first;
          this.techs.map((tech) => {
            options.push({
              id: tech.usuario,
              text: `${tech.placa.toUpperCase()} - ${tech.nombre} - ${
                tech.telefono
              }`,
            });
          });

          this.configTech = options;
          this.isLoadingTech = false;
        })
        .catch((e) => {
          // catch for showing errors
          console.log(e);
        });
    },
    deletePolylineBrigadeRoute() {
      this.markers = [];
      this.markersOt = [];
      this.path = [];
      this.formateAddres = [];
      this.infoWindowPos = null;
      this.infoWinOpen = false;
      this.currentMidx = null;
      this.infoWinContent = null;
      this.infoOptions = {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      };
    },
    directionsRenderer() {
      console.log("where going.");
      this.directionsService = new window.google.maps.DirectionsService();
      this.directionsDisplay = new window.google.maps.DirectionsRenderer();
      this.directionsDisplay.setMap(this.$refs.map.$mapObject);
      var vm = this;
      vm.directionsService.route(
        {
          origin: this.driving.start,
          destination: this.driving.end,
          travelMode: "DRIVING",
        },
        function (response, status) {
          if (status === "OK") {
            vm.directionsDisplay.setDirections(response);
          } else {
            console.log("Directions request failed due to " + status);
          }
        }
      );
    },
    geocodedAddress(position, id, date, hour, i) {
      this.formateAddres = [];
      let geocoder = new window.google.maps.Geocoder();
      return new Promise(function (resolve, reject) {
        geocoder.geocode({ location: position }, function (results, status) {
          // console.log({ date, hour, status });
          if (status === "OK") {
            if (results[0]) {
              resolve({ date, hour, addres: results[0].formatted_address, i });
            } else {
              reject(null);
            }
          }
        });
      }).then((data) => {
        const split = data.addres.split(",");
        this.formateAddres.push({
          uid: id,
          addres: data.addres,
          date: `${data.date} - ${data.hour}`,
          departament: split[split.length - 2],
          i: data.i,
        });
      });
    },
    simpleGeocode(position) {
      let geocoder = new window.google.maps.Geocoder();
      return new Promise(function (resolve, reject) {
        geocoder.geocode({ location: position }, function (results, status) {
          // console.log({ date, hour, status });
          if (status === "OK") {
            if (results[0]) {
              resolve({ address: results[0].formatted_address });
            } else {
              reject(null);
            }
          }
        });
      }).then((data) => {
        this.address = data.address;
      });
    },
    selectAll: function () {
      this.status = [];

      if (!this.allSelected) {
        for (let s in this.statusList) {
          this.status.push(this.statusList[s].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    getFiveLocations(arrLocations) {
      const length = arrLocations.length - 1;
      const values = {
        one: 0,
        two: Math.round(length / 4),
        tree: Math.round(length / 3),
        four: Math.round(length / 2),
        five: length,
      };
      const fiveValues = arrLocations.filter(
        (location, idx) =>
          idx === values.one ||
          idx === values.two ||
          idx === values.tree ||
          idx === values.four ||
          idx === values.five
      );
      // console.log({
      //   '0': arrLocations[0],
      //   '01': fiveValues[0],
      // });
      return fiveValues;
    },
    async getActivePosition(data) {
      this.activePositionData.position = {
        lat: parseFloat(data[0]),
        lng: parseFloat(data[1]),
      };
      this.center = this.activePositionData.position;
      this.zoom = 18;
      this.clickMap = [];
      // se puede implementar un promise para que muestre el valor que es y no el anterior
      // al dar click se pega con el valor de la posición anterior
      // favor verificar @santiagoP
      await this.simpleGeocode(this.activePositionData.position);
      if (this.address) {
        this.clickMap = [
          {
            id: Math.random(10000),
            position: this.activePositionData.position,
            infoText: `<div id="content" style="width: 300px !important;"><div id="bodyContent"><strong> Dirección (aproximada): ${this.address}</strong></div></div>`,
          },
        ];
        const isAlwaysOpen = true;
        this.toggleInfoWindow(this.clickMap[0], 0, isAlwaysOpen);
      }
    },
    showTableLocations() {
      this.showBrigadeTour = true;
    },
  },
  computed: {
    viewMarker() {
      return this.activePositionData.position;
    },
  },
  watch: {
    status() {
      this.allSelected = this.status.length == this.statusList.length;
    },
    address(newValue) {
      this.address = newValue;
    },
  },
};
</script>
